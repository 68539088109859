import SearchBar from "@/components/marketplace/search-bar";
import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { parseQueryString } from "@/lib/utils/parseQueryString";
import { useSetNavbarSearchQuery } from "../atoms/navbar-search-atom";
import { getSearchParamsFromSearchQuery } from "@/components/marketplace/search-page/utils/getSearchParamsFromSearchQuery";
import { parseQueryFromUrl } from "@/components/marketplace/search-page/utils/parseQueryFromUrl";
import { useSetSelectedCreators } from "@/components/marketplace/search-page/atoms/selected-creators-atom";

/**
 * Search input container along with the dedicated modal for medium to large
 * devices.
 */
const SearchInputModalContainer = () => {
  const router = useRouter();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const setNavbarSearchQuery = useSetNavbarSearchQuery();
  const setSelectedCreators = useSetSelectedCreators();
  const [value, setValue] = useState("");
  const [ignoreNextFocusChange, setIgnoreNextFocusChange] = useState(false);

  // Determine if we are on marketplace search page.
  const isMarketplaceSearchPage = router.pathname === "/marketplace";

  const handleSearchBarFocusChange = (focused: boolean) => {
    setTimeout(() => {
      if (ignoreNextFocusChange) {
        return;
      }

      if (!focused && isMarketplaceSearchPage) {
        const query = parseQueryFromUrl();
        setNavbarSearchQuery(query);
        setValue(query);
      }
    }, 0);
  };

  const executeSearch = async (search: string) => {
    setNavbarSearchQuery(search);
    setSelectedCreators([]);
    setIgnoreNextFocusChange(true);
    setTimeout(() => {
      searchInputRef.current!.blur();
    }, 100);

    // If marketplace search page is active, we need to update the query param
    // along with the other existing params.
    // Always send the query param to the marketplace
    const params = getSearchParamsFromSearchQuery(search, true);
    router.push({
      pathname: "/marketplace",
      query: params.toString(),
    });
  };

  const handleClearSearch = () => {
    setValue("");
    setTimeout(() => {
      searchInputRef.current!.blur();
    }, 100);

    if (isMarketplaceSearchPage) {
      setNavbarSearchQuery("");
      const params = getSearchParamsFromSearchQuery("", true);
      router.push({
        pathname: "/marketplace",
        query: params.toString(),
      });
    } else {
      setNavbarSearchQuery("");
    }
  };

  // Only call this at start of page load.
  useEffect(() => {
    const { query } = parseQueryString();
    setNavbarSearchQuery(query ?? "");
    setValue(query ?? "");
  }, [setNavbarSearchQuery]);

  const handleClear = () => {
    searchInputRef.current?.focus();
    setValue("");
    setNavbarSearchQuery("");

    if (isMarketplaceSearchPage) {
      // If marketplace search page is active, we need to update the query param
      // along with the other existing params.
      const params = getSearchParamsFromSearchQuery("", true);
      router.push({
        pathname: "/marketplace",
        query: params.toString(),
      });
    }
  };

  return (
    <div className="flex-1 flex items-center justify-center relative">
      <SearchBar
        ref={searchInputRef}
        value={value}
        onChange={(e) => setValue(e.currentTarget.value)}
        onFocusChange={handleSearchBarFocusChange}
        onKeyDown={(event: any) => {
          if (event.key === "Enter") {
            executeSearch(searchInputRef?.current?.value ?? "");
          }
        }}
        onSearchClick={() =>
          executeSearch(searchInputRef?.current?.value ?? "")
        }
        onEscapePressed={handleClearSearch}
        onClear={handleClear}
      />
    </div>
  );
};

export default SearchInputModalContainer;
