import {
  Artist,
  Beat,
  DetailedOrderItem,
  LicenseRight,
  SearchCategory,
  SoundKit,
  ViewObject,
} from "../models/models";
import { BaseEntityType, Categories } from "../models/enums";
import api from "./api-config";
import { Asset } from "@/components/account/audiolab/controls/types";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { AccountsListResponse } from "@/components/navigation/navbar/types";
import { Option } from "@/models/sorting-filtering";
import {
  License,
  RecordingLicenseExclusive,
  RecordingLicenseNonExclusive,
  SyncLicenseBasic,
  SyncLicensePro,
} from "@/components/account/audiolab/pricing/pricing-beat";

dayjs.extend(duration);

export function isSoundkit(object: ViewObject): object is SoundKit {
  return (object as SoundKit).type == "sounds";
}
export function isBeat(object: ViewObject): object is Beat {
  return (object as Beat).type == "beats";
}
export function isSong(object: ViewObject): object is Beat {
  return (object as Beat).type == "songs";
}

export function isArtist(object: Artist): object is Artist {
  return (object as Artist).type == "creators";
}

export type RawEntityType = "sounds" | "beats" | "creators" | "songs";

export const getEntityType = (object: Beat | SoundKit) => {
  const entityType = isBeat(object)
    ? BaseEntityType.beat
    : isSong(object)
    ? BaseEntityType.song
    : BaseEntityType.soundkit;

  return entityType;
};

export const getEntityTypeByType = (type: RawEntityType) => {
  const entityType =
    type === "beats"
      ? BaseEntityType.beat
      : type === "songs"
      ? BaseEntityType.song
      : BaseEntityType.soundkit;

  return entityType;
};

export const getEntityTypeByCategory = (category: Categories) => {
  switch (category) {
    case Categories.beats:
      return BaseEntityType.beat;
    case Categories.sounds:
      return BaseEntityType.soundkit;
    case Categories.songs:
      return BaseEntityType.song;
    case Categories.creators:
      return null;
    default:
      return null;
  }
};

export function getViewObjectUrl(item: any): string {
  if (isSoundkit(item)) {
    return `/sounds/${slugify(item.name)}/${item.id}`;
  } else if (isBeat(item)) {
    return `/beats/${slugify(item.name)}/${item.id}`;
  } else if (isArtist(item)) {
    return `/creators/${slugify(item.name)}/${item.id}`;
  } else if (isSong(item)) {
    return `/songs/${slugify(item.name)}/${item.id}`;
  } else {
    return "/";
  }
}

export function isRecordingLicense(license: string) {
  return (
    license === "Non-Exclusive" ||
    license === "Exclusive" ||
    license === "Buy-Out"
  );
}

export function isSyncLicense(license: string) {
  return license === "Basic" || license === "Pro" || license === "Enterprise";
}

export const isMonetize = (orderItem: DetailedOrderItem) => {
  try {
    const parsedMetadata = orderItem.metadata;
    return parsedMetadata?.addons?.includes("music-video-monetize");
  } catch (error) {
    return false;
  }
};

export const slugify = (str: string | undefined) =>
  (str || "")
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

export const switchRowsAndColumns = (
  tracks: Beat[],
  numRows: number
): Beat[] => {
  const matrix: Beat[][] = [];

  // Determine the number of columns in the matrix
  const numColumns = Math.ceil(tracks.length / numRows);

  // Fill the matrix with the tracks
  for (let col = 0; col < numColumns; col++) {
    const currentCol: Beat[] = [];

    for (let row = 0; row < numRows; row++) {
      const index = col * numColumns + row;
      if (index < tracks.length) {
        currentCol.push(tracks[index]);
      }
    }
    matrix.push(currentCol);
  }

  const transposed = transposeMatrix(matrix);

  return matrixToArray(transposed);
};

export const transposeMatrix = (matrix: Beat[][]) => {
  const rows = matrix.length,
    cols = matrix[0]?.length;

  const grid = [];
  for (let j = 0; j < cols; j++) {
    grid[j] = Array(rows);
  }
  for (let i = 0; i < rows; i++) {
    for (let j = 0; j < cols; j++) {
      grid[j][i] = matrix[i][j];
    }
  }

  return grid;
};

export const matrixToArray = (matrix: Beat[][]): Beat[] => {
  const array: Beat[] = [];

  for (const row of matrix) {
    for (const track of row) {
      array.push(track);
    }
  }

  return array;
};

export const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

export const rotateSize = (width: number, height: number, rotation: number) => {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
};

export const formatDate = (dateStr: string) => {
  let date = dayjs(dateStr);
  let now = dayjs();
  let diff = Math.abs(now.diff(date));

  let diffMinutes = dayjs.duration(diff).asMinutes();
  let diffHours = dayjs.duration(diff).asHours();
  let diffDays = dayjs.duration(diff).asDays();
  let diffWeeks = dayjs.duration(diff).asWeeks();
  let diffMonths = dayjs.duration(diff).asMonths();

  if (diffMinutes < 1) {
    return `A moment ago`;
  } else if (diffHours < 1) {
    return `${Math.floor(diffMinutes)} minutes ago`;
  } else if (diffDays < 1) {
    return `${Math.floor(diffHours)} hours ago`;
  } else if (diffWeeks < 1) {
    return `${Math.floor(diffDays)} days ago`;
  } else if (diffMonths < 1) {
    return `${Math.floor(diffWeeks)} weeks ago`;
  } else if (diffMonths > 12) {
    return `${Math.floor(diffMonths)} months ago`;
  } else {
    return `${date.month()}/${date.day()}/${date.year()}`;
  }
};

export const formatDateAlt = (dateStr: string) => {
  let date = new Date(dateStr);

  const month = date.getMonth();
  const day = date.getDay();
  const year = date.getFullYear();

  return `${month.toString().length === 1 && "0"}${month}.${
    day.toString().length === 1 && "0"
  }${day}.${year}`;
};

export const capitalizeFirst = (text: string) => {
  return `${text.at(0)?.toUpperCase()}${text.slice(1)}`;
};

export const formatTime = (secondsStr: string) => {
  const time = Math.round(parseFloat(secondsStr));

  const minutes = Math.floor(time / 60);
  const remainder = time % 60;

  const returnedMins = minutes.toString().padStart(2);
  const returnedSecs = remainder.toString().padStart(2, "0");
  return `${returnedMins}:${returnedSecs}`;
};

export const calculatePercentage = async (
  item: Beat | SoundKit,
  automated: any
) => {
  let total: number = 0;
  let missing: string[] = [];
  const name = item?.name?.toLowerCase();

  // If there is a name set already
  if (
    !name?.includes?.(".wav") &&
    !name?.includes?.(".mp3") &&
    !name?.includes?.(".zip") &&
    !name?.includes?.(".aif") &&
    !name?.includes?.(".aiff")
  ) {
    total = total + 10;
  } else {
    missing.push("- Set a title");
  }

  // If there is an image set
  const suffix =
    process.env.NEXT_PUBLIC_API_URL === "https://api.licenselounge.com"
      ? ""
      : "-test";

  try {
    const bucket = `licenselounge-public${suffix}`;
    const key = `${item.id}/artwork.jpg`;

    const resp = await api.get(`audiolab/read?bucket=${bucket}&key=${key}`);
    if (resp.data.status) total = total + 10;
  } catch (error) {
    missing.push("- Pick the Artwork");
  }

  // If prices set
  if (
    (isBeat(item) && item.licenseRights) ||
    (isSong(item) && item.licenseRights && item.licenseRights.length > 0) ||
    (isSoundkit(item) && item.price)
  ) {
    total = total + 20;
  } else {
    missing.push("- Add Pricing details");
  }

  // If track details
  if (isBeat(item) || isSong(item)) {
    // If Styles
    if (item.styles?.length > 0 || automated.styles) {
      total = total + 5;
    } else {
      missing.push("- Tag the Genre and Styles");
    }
    if (item.tempo !== 0 || automated.bpm) {
      total = total + 2.5;
    } else {
      missing.push("- Set the BPM");
    }
    if (item.keySignature || automated.key) {
      total = total + 2.5;
    } else {
      missing.push("- Set the Key signature");
    }
    if (item.vocals || automated.vocals !== undefined) {
      total = total + 5;
    } else {
      missing.push("- Mark the Vocals type");
    }
    if (item.moods?.length > 0 || automated.mood) {
      total = total + 5;
    } else {
      missing.push("- Pick a Mood");
    }

    if (item.name.endsWith(".mp3")) {
      missing = [];
      missing.push(
        "MP3 originals are not eligible for the marketplace at this time"
      );
    }
  } else if (isSoundkit(item)) {
    if (item.styles?.length > 0) {
      total = total + 5;
    } else {
      missing.push("- Tag the Genre and Styles");
    }

    if (item.types?.length > 0) {
      total = total + 5;
    } else {
      missing.push("- Pick the Types");
    }

    // If description (soundkit only)
    if (item.description) {
      total = total + 15;
    } else {
      missing.push("- Write a Description");
    }

    // If preview track (soundkit only)
    if (item.sample) {
      total = total + 15;
    } else {
      missing.push("- Add an MP3 Preview track");
    }
  }

  // If Assets (stems, files , etc)
  if (
    (isBeat(item) && automated.assets?.length > 0) ||
    (isSong(item) && automated.assets?.length > 0)
  ) {
    if (automated.assets.find((ass: Asset) => ass.name === "original")) {
      total = total + 10;
    }
    if (automated.assets.find((ass: Asset) => ass.name === "mastering")) {
      total = total + 10;
    }
    if (automated.assets.length > 2) {
      total = total + 20;
    }
  }

  if (isSoundkit(item) && automated.assets?.length > 0) total = total + 20;

  return { percentage: total, hints: missing };
};

export const equalArrays = (arr1: string[], arr2: string[]) => {
  // check if the lengths are equal
  if (arr1.length !== arr2.length) {
    return false;
  }

  // sort the arrays
  arr1.sort();
  arr2.sort();

  // compare each element
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  // if no difference found, return true
  return true;
};

export const getTimeStamp = () => {
  return new Date().getTime();
};

export const parseSearchCategory = (searchCategory?: SearchCategory | null) => {
  switch (searchCategory?.name) {
    case "Beats":
      return BaseEntityType.beat;
    case "Sounds":
      return BaseEntityType.soundkit;
    case "Songs":
      return BaseEntityType.song;
    default:
      return BaseEntityType.beat;
  }
};

export const mapAccountToOption = (account: AccountsListResponse): Option => ({
  id: account.id,
  name: account.name,
  value: account.id,
  image: account.profilePicUrl,
});

// Comparisons for checking LicenseRights updates for Beats/Songs
export const transformToRecordingLicenseNonExclusive = (
  license: LicenseRight
): RecordingLicenseNonExclusive => {
  const basePrice = parseFloat(license.price);
  const fileTypes = license.files.reduce((acc, file) => {
    acc[file.type] = (basePrice + parseFloat(file.extraPrice)).toFixed(2);
    return acc;
  }, {} as { [key: string]: string });
  return {
    id: license.id,
    MP3: fileTypes["MP3"] || "",
    WAV: fileTypes["WAV"] || "",
    "STEMS + WAV": fileTypes["STEMS + WAV"] || "",
    "Unlimited Distribution": parseFloat(license.unlimitedDistribution).toFixed(
      2
    ),
    "Monetize Music Video": parseFloat(license.monetizeMusic).toFixed(2),
  };
};

export const transformToRecordingLicenseExclusive = (
  license: LicenseRight
): RecordingLicenseExclusive => {
  const basePrice = parseFloat(license.price);
  const fileTypes = license.files.reduce((acc, file) => {
    acc[file.type] = (basePrice + parseFloat(file.extraPrice)).toFixed(2);
    return acc;
  }, {} as { [key: string]: string });
  return {
    id: license.id,
    "STEMS + WAV": fileTypes["STEMS + WAV"] || "",
    display: true,
  };
};

export const transformToSyncLicenseBasic = (
  license: LicenseRight
): SyncLicenseBasic => {
  const basePrice = parseFloat(license.price);
  const fileTypes = license.files.reduce((acc, file) => {
    acc[file.type] = (basePrice + parseFloat(file.extraPrice)).toFixed(2);
    return acc;
  }, {} as { [key: string]: string });
  return {
    id: license.id,
    MP3: fileTypes["MP3"] || "",
    WAV: fileTypes["WAV"] || "",
    "STEMS + WAV": fileTypes["STEMS + WAV"] || "",
  };
};

export const transformToSyncLicensePro = (
  license: LicenseRight
): SyncLicensePro => {
  const basePrice = parseFloat(license.price);
  const fileTypes = license.files.reduce((acc, file) => {
    acc[file.type] = (basePrice + parseFloat(file.extraPrice)).toFixed(2);
    return acc;
  }, {} as { [key: string]: string });
  return {
    id: license.id,
    WAV: fileTypes["WAV"] || "",
    "STEMS + WAV": fileTypes["STEMS + WAV"] || "",
  };
};

export const transformLicenses = (
  licenses: LicenseRight[]
): Record<string, License> => {
  if (licenses?.length > 0) {
    return licenses.reduce((acc, license) => {
      switch (license.name) {
        case "Non-Exclusive":
          acc[license.name] = transformToRecordingLicenseNonExclusive(license);
          break;
        case "Exclusive":
          acc[license.name] = transformToRecordingLicenseExclusive(license);
          break;
        case "Basic":
          acc[license.name] = transformToSyncLicenseBasic(license);
          break;
        case "Pro":
          acc[license.name] = transformToSyncLicensePro(license);
          break;
        default:
          break;
      }
      return acc;
    }, {} as Record<string, License>);
  } else {
    return {} as Record<string, License>;
  }
};

export const compareRecordingLicenseNonExclusive = (
  license1: RecordingLicenseNonExclusive | undefined,
  license2: RecordingLicenseNonExclusive | undefined
): boolean => {
  if (!license1 || !license2) {
    return !license1 && !license2;
  } else {
    return (
      license1["STEMS + WAV"] === license2["STEMS + WAV"] &&
      license1.MP3 === license2.MP3 &&
      license1.WAV === license2.WAV &&
      license1["Unlimited Distribution"] ===
        license2["Unlimited Distribution"] &&
      license1["Monetize Music Video"] === license2["Monetize Music Video"]
    );
  }
};

export const compareRecordingLicenseExclusive = (
  license1: RecordingLicenseExclusive | undefined,
  license2: RecordingLicenseExclusive | undefined
): boolean => {
  if (!license1 || !license2) {
    return !license1 && !license2;
  } else {
    return (
      license1["STEMS + WAV"] === license2["STEMS + WAV"] &&
      license1.display === license2.display
    );
  }
};

export const compareSyncLicenseBasic = (
  license1: SyncLicenseBasic | undefined,
  license2: SyncLicenseBasic | undefined
): boolean => {
  if (!license1 || !license2) {
    return !license1 && !license2;
  } else {
    return (
      license1["STEMS + WAV"] === license2["STEMS + WAV"] &&
      license1.MP3 === license2.MP3 &&
      license1.WAV === license2.WAV
    );
  }
};

export const compareSyncLicensePro = (
  license1: SyncLicensePro | undefined,
  license2: SyncLicensePro | undefined
): boolean => {
  if (!license1 || !license2) {
    return !license1 && !license2;
  } else {
    return (
      license1["STEMS + WAV"] === license2["STEMS + WAV"] &&
      license1.WAV === license2.WAV
    );
  }
};
