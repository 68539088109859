import { IconType } from "../icon-props";

const UserOutlineIcon: IconType = (
  <>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_836_3061)">
        <path
          d="M3.33337 18.3335C3.33337 16.5654 4.03575 14.8697 5.286 13.6195C6.53624 12.3692 8.23193 11.6668 10 11.6668C11.7682 11.6668 13.4638 12.3692 14.7141 13.6195C15.9643 14.8697 16.6667 16.5654 16.6667 18.3335H15C15 17.0074 14.4733 15.7356 13.5356 14.798C12.5979 13.8603 11.3261 13.3335 10 13.3335C8.67396 13.3335 7.40219 13.8603 6.46451 14.798C5.52682 15.7356 5.00004 17.0074 5.00004 18.3335H3.33337ZM10 10.8335C7.23754 10.8335 5.00004 8.596 5.00004 5.8335C5.00004 3.071 7.23754 0.833496 10 0.833496C12.7625 0.833496 15 3.071 15 5.8335C15 8.596 12.7625 10.8335 10 10.8335ZM10 9.16683C11.8417 9.16683 13.3334 7.67516 13.3334 5.8335C13.3334 3.99183 11.8417 2.50016 10 2.50016C8.15837 2.50016 6.66671 3.99183 6.66671 5.8335C6.66671 7.67516 8.15837 9.16683 10 9.16683Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_836_3061">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);

export default UserOutlineIcon;
