import axios from "axios";
import { isEmpty } from "lodash";
import { getSession } from "next-auth/react";

const baseURL = process.env.NEXT_PUBLIC_API_URL,
  isClient = typeof window !== "undefined";

const api = axios.create({
  baseURL: baseURL || "https://staging-api.licenselounge.com",
  timeout: 300000,
});

if (isClient) {
  api.interceptors.request.use(async (config) => {
    const impersonate_artist_id = document.cookie.replace(
      /(?:(?:^|.*;\s*)impersonate-artist-id\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    if (!isEmpty(impersonate_artist_id)) {
      config.headers["impersonate-artist-id"] = impersonate_artist_id;
    }

    return config;
  });
} else {
  getSession().then((session) => {
    if (session?.user?.impersonate_artist_id) {
      api.defaults.headers.common["impersonate-artist-id"] =
        session?.user?.impersonate_artist_id;
    }
  });
}

export default api;
