import { Fragment, ReactNode, useEffect, useState } from "react";
import Icon from "@/components/icons/icon";
import Image from "next/image";
import { Option } from "./types";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import RecursiveMenuItems from "./recursive-menu-items";
import { capitalize } from "lodash";
import { Transition } from "@headlessui/react";
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import ArrowDown from "@/components/icons/lib/arrow-down";
import User from "@/components/icons/lib/user";
import SafeImage from "../safe-image";

type Side = "top" | "right" | "bottom" | "left";
type Align = "start" | "center" | "end";

interface DropdownAltProps {
  id?: string;
  title?: string;
  children?: ReactNode;
  icon?: ReactNode;
  image?: string | null;
  options: Option[];
  value?: string;
  onItemClick: (option: any) => void;
  onDropdownShow?: () => void;
  className?: string;
  side?: Side;
  align?: Align;
  showArrow?: boolean;
  dropdownMenuArrowClassname?: string;
}

const DropdownAlt = ({
  id,
  title,
  children,
  icon,
  image,
  options,
  value,
  onItemClick,
  onDropdownShow,
  className,
  side,
  align,
  showArrow = true,
  dropdownMenuArrowClassname = "",
}: DropdownAltProps) => {
  const [selectedOption, setSelectedOption] = useState<string>(
    value || "Sort by"
  );
  const [open, setOpen] = useState(false);

  const handleOnClick = (option: Option) => {
    if (!option.children) {
      onItemClick?.(option);
    }
    setSelectedOption(option.name);
  };

  const handleOpenChange = (open: boolean) => {
    onDropdownShow?.();
    setOpen(open);
  };

  return (
    <DropdownMenu.Root onOpenChange={handleOpenChange}>
      <DropdownMenu.Trigger asChild>
        <button
          id={id}
          className="navigation-btn-menu flex w-fit items-center justify-center rounded-md gap-1 text-nowrap focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 cursor-pointer"
        >
          {icon && <Icon icon={icon} />}
          {!title && !children && !image && !value && selectedOption}
          {!title && !children && !image && value}
          {title || children}
          {image && (
            <SafeImage
              defaultIcon={
                <div className="flex justify-center items-center w-[28px] h-[28px] bg-gradient-to-br from-purple to-green rounded-full">
                  <Icon
                    icon={User}
                    color="white"
                    className="h-full w-full mx-2"
                  />
                </div>
              }
              src={image}
              alt="image"
              width={28}
              height={28}
              objectFit="cover"
              className="rounded-full"
              blurDataURL="/assets/images/placeholder.png"
              noCache={false}
            />
          )}
          {showArrow && (
            <Icon icon={ArrowDown} className={dropdownMenuArrowClassname} />
          )}
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal forceMount>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          show={open}
        >
          <DropdownMenu.Content
            className={`w-56 z-[2000] divide-y divide-grayLight rounded-2xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-visible ${className}`}
            sideOffset={5}
            side={side}
            align={align}
            forceMount
            sticky="always"
            avoidCollisions
          >
            {options.map((option: any, index: number) =>
              option.children ? (
                <RecursiveMenuItems
                  key={option.id}
                  option={option}
                  onItemClick={onItemClick}
                />
              ) : (
                <DropdownMenu.Item
                  key={option.id}
                  id={`dropdown-button-option-${index}`}
                  onClick={() => {
                    handleOnClick(option);
                  }}
                  className={`navigation-button-${index} flex h-big w-full items-center px-4 hover:bg-backgroundLight group/parent first:rounded-t-[14px] last:rounded-b-[14px] cursor-pointer`}
                >
                  {!option.image &&
                    option.icon &&
                    (option.icon === User ? (
                      <div className="w-7 h-7 mr-2 min-w-[24px]">
                        <div className="flex justify-center items-center h-full w-full bg-gradient-to-br from-purple to-green p-[25%] rounded-full">
                          <Icon
                            icon={option.icon}
                            color="white"
                            className="h-full w-full"
                          />
                        </div>
                      </div>
                    ) : !option.loading ? (
                      <Icon
                        icon={option.icon}
                        className={`h-[20px] w-[20px] min-w-[20px] mr-3 ${
                          option.name === "Audiolab" && "rotate-90"
                        }`}
                      />
                    ) : (
                      <ArrowPathIcon className="animate-spin h-[20px] w-[20px] mr-3" />
                    ))}
                  {option.image && (
                    <div className="mr-3">
                      <SafeImage
                        defaultIcon={
                          <div className="flex justify-center items-center w-[24px] h-[24px] bg-gradient-to-br from-purple to-green rounded-full">
                            <Icon
                              icon={User}
                              color="white"
                              className="h-full w-full mx-2"
                            />
                          </div>
                        }
                        src={option.image}
                        alt="image"
                        width={28}
                        height={28}
                        className="rounded-full"
                        blurDataURL="/assets/images/placeholder.png"
                        noCache={false}
                      />
                    </div>
                  )}
                  <p
                    className={
                      option.name === selectedOption
                        ? "font-bold"
                        : "font-light"
                    }
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {capitalize(option.name)}
                  </p>
                </DropdownMenu.Item>
              )
            )}
          </DropdownMenu.Content>
        </Transition>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default DropdownAlt;
