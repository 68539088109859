import { atom, useAtom, useSetAtom } from "jotai";

export const navbarSearchQueryAtom = atom("");

/**
 * Search term from the search bar inside the navbar. It will synchronize the
 * value from that search bar to be used in independent filter contexts.
 */
export const useNavbarSearchQueryContext = () => useAtom(navbarSearchQueryAtom);

export const useSetNavbarSearchQuery = () => useSetAtom(navbarSearchQueryAtom);
