import { Combobox } from "@headlessui/react";
import { useRouter } from "next/router";
import { useState } from "react";
import { Size } from "../../models/enums";
import PrimaryButton from "../buttons/primary-button";
import Icon from "../icons/icon";
import { getSearchParamsFromSearchQuery } from "../marketplace/search-page/utils/getSearchParamsFromSearchQuery";
import Close from "../icons/lib/close";
import Search from "../icons/lib/search";
import { useSetNavbarSearchQuery } from "../navigation/navbar/atoms/navbar-search-atom";
import { cx } from "@/lib/utils/className.utils";

interface SearchBarMobileProps {
  className?: string;
  onClose: () => void | undefined;
}

const SearchBarMobile = ({ className, onClose }: SearchBarMobileProps) => {
  const router = useRouter();
  const setNavbarSearchQuery = useSetNavbarSearchQuery();

  const [query, setQuery] = useState("");

  const handleQueryChange = (newQuery: string) => {
    setQuery(newQuery);
  };

  const executeSearch = (search: string) => {
    const params = getSearchParamsFromSearchQuery(search, true);
    setNavbarSearchQuery(search);
    router.push({
      pathname: "/marketplace",
      query: params.toString(),
    });
    onClose();
  };

  const handleClearSearch = () => {
    if (query?.length === 0) {
      return;
    }
    setQuery("");
  };

  return (
    <div className={cx(`flex flex-col space-y-5 w-full`, className)}>
      <div className="bg-white rounded-3xl p-4">
        <Combobox>
          <div className="flex items-center justify-between w-full h-big rounded-full border-[1px] border-grayLight pl-5">
            <Combobox.Input
              id="navigation-input-search"
              onKeyDown={(event: any) => {
                if (event.key === "Enter") {
                  executeSearch(query ?? "");
                }
                if (event.key === "Escape") {
                  handleClearSearch();
                }
              }}
              onChange={(event) => handleQueryChange(event.currentTarget.value)}
              value={query}
              placeholder="Type to search"
              className={`w-[80%] h-full border-none focus:ring-0 bg-transparent px-0 truncate`}
            />

            {/* Search button */}
            {query?.length > 0 ? (
              <button
                id="navigation-button-search"
                onClick={() => {
                  setQuery("");
                }}
                className="mr-5"
              >
                <Icon icon={Close} color="gray" />
              </button>
            ) : (
              <button
                id="navigation-iconButton-search"
                onClick={() => executeSearch(query ?? "")}
                className="flex items-center justify-center bg-green rounded-full h-medium w-medium mr-1"
              >
                <Icon icon={Search} />
              </button>
            )}
          </div>
        </Combobox>
      </div>

      <div className="fixed flex items-center justify-end bottom-10 left-0 bg-background w-full h-[120px] border-t-[1px] border-grayLight px-10">
        <PrimaryButton
          id="navigation-button-search"
          text="Search"
          size={Size.md}
          onClick={() => executeSearch(query ?? "")}
        />
      </div>
    </div>
  );
};

export default SearchBarMobile;
