import DropdownAlt from "@/components/dropdown/dropdown-alt";
import { Option } from "@/models/sorting-filtering";
import { useSession } from "next-auth/react";
import React, { useEffect, useState } from "react";
import {
  getLabelArtistList,
  removeLabelImpersonator,
  updateLabelImpersonator,
} from "@/lib/label-manager";
import { useCookies } from "react-cookie";
import { mapAccountToOption } from "@/lib/helpers";
import { useGetProfileTimestampContext } from "@/lib/atoms/profile";

const ArtistPicker = () => {
  const session = useSession();
  const [, setCookie, removeCookie] = useCookies();

  const [labelArtists, setLabelArtists] = useState<Option[]>(() => {
    const storedLabelArtists = sessionStorage.getItem("labelArtists");
    return storedLabelArtists ? JSON.parse(storedLabelArtists) : [];
  });
  const [selectedArtist, setSelectedArtist] = useState<Option>(() => {
    const storedSelectedArtist = sessionStorage.getItem("selectedArtist");
    return storedSelectedArtist
      ? JSON.parse(storedSelectedArtist)
      : ({
          id: "No Account",
          name: "No Account",
          value: undefined,
        } as Option);
  });

  // Hooks
  useEffect(() => {
    const fetchLabelArtists = async () => {
      try {
        const artistsOptions = await getLabelArtistList(
          session.data?.user.accessToken as string
        );
        const mappedOptions = artistsOptions.map(
          mapAccountToOption
        ) as Option[];
        mappedOptions.sort((a, b) => a.name.localeCompare(b.name));

        let profilePicUrl;
        if (session.data?.user?.impersonate) {
          profilePicUrl = session.data.user.impersonate.labelProfilePicUrl;
        } else {
          profilePicUrl = session.data?.user?.profilePicUrl;
        }

        mappedOptions.unshift({
          id: "No Account",
          name: "No Account",
          value: undefined,
        } as Option);
        sessionStorage.setItem("labelArtists", JSON.stringify(mappedOptions));
        setLabelArtists(mappedOptions);
      } catch (e) {
        throw e;
      }
    };
    if (
      session?.status === "authenticated" &&
      session.data?.user.isLabelAccount
    ) {
      // fetching the possible labelArtists that can be selected
      fetchLabelArtists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.status, labelArtists.length]);

  // Helper Functions
  const handleArtistsSelect = async (option: Option) => {
    if (!session?.data?.user?.isLabelAccount) {
      return;
    }
    if (selectedArtist.id === option.id) {
      return;
    } else if (option.name === "No Account") {
      // Remove labelImpersonate from table in backend
      await removeLabelImpersonator(session.data?.user.accessToken as string);

      // Update Client Side Frontend API calls to include artist_id in header
      await removeCookie("impersonate-artist-id");
      await setSelectedArtist(option);
      await sessionStorage.setItem("selectedArtist", JSON.stringify(option));
      const storageEvent = new CustomEvent("onSessionStorageChange", {
        detail: {
          key: "selectedArtist",
          newValue: option.id,
        },
      });

      // Update ServerSide Frontend API calls to include artist_id in header
      await session.update({ impersonate_artist_id: "delete" });
      await window.dispatchEvent(storageEvent);
    } else {
      // Send to the impersonate endpoint
      await updateLabelImpersonator(
        option.id as string,
        session.data?.user.accessToken as string
      );

      // Update Client Side Frontend API calls to include artist_id in header
      await setCookie("impersonate-artist-id", option.id);
      setSelectedArtist(option);
      await sessionStorage.setItem("selectedArtist", JSON.stringify(option));
      const storageEvent = new CustomEvent("onSessionStorageChange", {
        detail: {
          key: "selectedArtist",
          newValue: option.id as string,
        },
      });
      // Update ServerSide Frontend API calls to include artist_id in header
      await session.update({ impersonate_artist_id: option.id });
      await window.dispatchEvent(storageEvent);
    }
  };

  const timestamp = useGetProfileTimestampContext();

  if (session?.status === "authenticated") {
    return (
      <div className={`flex text-white font-righteous`}>
        {session.data.user.isLabelAccount &&
          labelArtists !== undefined &&
          labelArtists.length > 0 && (
            <>
              <DropdownAlt
                id="label-dropdown-account-selection"
                options={labelArtists!}
                value={selectedArtist?.name}
                onItemClick={(option) => {
                  handleArtistsSelect(option);
                }}
                showArrow={true}
                dropdownMenuArrowClassname="fill-white opacity-40"
                image={
                  session.data?.user?.profilePicUrl
                    ? `${session?.data?.user.profilePicUrl}?timestamp=${timestamp}`
                    : null
                }
              />
            </>
          )}
      </div>
    );
  } else {
    return <></>;
  }
};

export default ArtistPicker;
