import api from "./api-config";

export const getLabelArtistList = async (token: string) => {
  const res = await api.get(`/auth/impersonate-list`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

export const updateLabelImpersonator = async (
  artistId: string,
  token: string
) => {
  const res = await api.post(`auth/impersonate/${artistId}`, null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

export const removeLabelImpersonator = async (token: string) => {
  await api.delete(`/auth/impersonate`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
