import Headphones from "@/components/icons/lib/headphones";
import Song from "@/components/icons/lib/song";
import Soundkits from "@/components/icons/lib/soundkits";
import { NavbarLink } from "./types";
import UserOutlineIcon from "@/components/icons/lib/user-outline";

export const categoryLinks: NavbarLink[] = [
  {
    id: "beats",
    name: "Beats",
    icon: Headphones,
    href: "/marketplace?viewType=grid&categories=beats&sorting=1",
  },
  {
    id: "songs",
    name: "Songs",
    icon: Song,
    href: "/marketplace?viewType=grid&categories=songs&sorting=1",
  },
  {
    id: "soundkits",
    name: "Sound Kits",
    icon: Soundkits,
    href: "/marketplace?viewType=grid&categories=sounds&sorting=1",
  },
  {
    id: "creators",
    name: "Creators",
    icon: UserOutlineIcon,
    href: "/marketplace?viewType=grid&categories=all&sorting=1",
  },
];

export const defaultLinks: NavbarLink[] = [
  {
    id: "discover",
    name: "Discover",
    href: "",
    subLinks: categoryLinks,
  },
];
